@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}

.hero {
  background: url(./images/bg-image.jpg) top center no-repeat;
  background-size: cover;
  height: 100vh;
}

.hero2 {
  background: url(./images/hero2.jpg) top center no-repeat;
  background-size: cover;
  height: 100vh;
}

.footer {
  background: url(./images/footer.svg) top center no-repeat;
  background-size: cover;
}

/* Style for the button */
.appointment-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  cursor: pointer;
}

.toggle-button {
  top: 12px;
  right: 5px;
}

.card-container1 {
  /* width: 90%; */
  /* max-width: 1000px; */
  /* margin: 50px auto; */
  padding: 50px; 
}

.cupping-image-card {
  width: 50%;
  height: 500px;
  background: url(./images/cupping2.jpg) no-repeat center /cover;
  border-radius: 8px;
}

.cupping-content-card {
  width: 50%;
  height: 350px;
  padding: 30px;
  border-radius: 8px;
  margin-left: -100px;
}

.card-container2 {
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  /* margin: 50px auto;
  padding: 50px; */
}

.cupping-image-card2 {
  width: 50%;
  height: 500px;
  background: url(./images/cupping1.jpg) no-repeat center /cover;
  border-radius: 8px;
}

.cupping-content-card2 {
  width: 50%;
  height: 350px;
  padding: 30px;
  border-radius: 8px;
  margin-right: -100px;
}

.cupping-image-card3 {
  width: 50%;
  height: 500px;
  background: url(./images/cupping3.jpg) no-repeat center /cover;
  border-radius: 8px;
}

@media only screen and (max-width: 768px){
  .card-container1{
    width: 100%;
    flex-direction: column;
    margin: 50px auto;
  }
  .card-container2{
    width: 100%;
    flex-direction: column-reverse;
    margin: 50px auto;
  }
  .cupping-image-card, .cupping-image-card2, .cupping-image-card3 {
    width: 100%;
  }
  .cupping-content-card{
    width: 80%;
    margin-left: 0;
    margin-top: -100px;
  }
  .cupping-content-card2{
    width: 80%;
    margin-right: 0;
    margin-top: -100px;
  }
}
